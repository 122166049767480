import React from 'react'
import classes from "./Banner.module.scss"
import img1 from "../../../assets/img/main/1/1.png"
import img1Mob from "../../../assets/img/main/1/mob.png"
import Button from "../../GlobalComponents/Button/Button"
import { Trans, useTranslation } from 'react-i18next'
const Banner = () => {

    const { t } = useTranslation("");

    return (
        <div className={[classes.banner, ""].join(" ")}>
            <div className={[classes.bannerBody, ""].join(" ")}>
                <div className={[classes.bannerImg, ""].join(" ")}>
                    <img src={img1} alt="Юридическая и правовая помощь" />
                </div>
                <div className="container">
                    <div className={[classes.bannerContent, ""].join(" ")}>
                        <p className={[classes.bannerOverTitle, "font-36"].join(" ")}>
                            Legal Balance
                        </p>
                        <h1 className={[classes.bannerTitle, "font-64"].join(" ")}>
                            <Trans>main_1_1</Trans>
                        </h1>
                        <p className={[classes.bannerText, "font-20"].join(" ")}>
                            <Trans>main_1_2</Trans>
                        </p>
                        <div className={[classes.bannerBtn, ""].join(" ")}>
                            <Button><Trans>btn_consultation</Trans></Button>
                        </div>
                    </div>
                </div>
                <div className={[classes.bannerMob, ""].join(" ")}>
                    <img src={img1Mob} alt="Юридическая и правовая помощь" />
                </div>

            </div>
        </div>
    )
}

export default Banner