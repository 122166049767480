import React, { useState } from 'react'
import classes from "./Slider.module.scss"
import img1 from "../../../assets/img/main/5/left.png"
import imgI1 from "../../../assets/img/main/5/1.png"
import imgI2 from "../../../assets/img/main/5/2.png"
import imgI3 from "../../../assets/img/main/5/3.png"
import imgI4 from "../../../assets/img/main/5/4.png"
import imgI5 from "../../../assets/img/main/5/5.png"
import imgI6 from "../../../assets/img/main/5/6.png"
import imgI7 from "../../../assets/img/main/5/7.png"
import imgI8 from "../../../assets/img/main/5/8.png"
import imgI9 from "../../../assets/img/main/5/9.png"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import { Trans, useTranslation } from 'react-i18next'


const Slider = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState({})
    const items = [
        { id: 1, name: "main_5_item11", img: imgI1 },
        { id: 2, name: "main_5_item21", img: imgI2 },
        { id: 3, name: "main_5_item31", img: imgI3 },
        { id: 4, name: "main_5_item41", img: imgI4 },
        { id: 5, name: "main_5_item51", img: imgI5 },
        { id: 6, name: "main_5_item61", img: imgI6 },
        { id: 7, name: "main_5_item71", img: imgI7 },
        { id: 8, name: "main_5_item81", img: imgI8 },
        { id: 9, name: "main_5_item91", img: imgI9 },
    ]


    return (
        <div className={[classes.block, "mb"].join(" ")}>
            <div className={[classes.blockBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.blockRow, ""].join(" ")}>
                        <div className={[classes.blockRowLeft, ""].join(" ")}>
                            <h2 className={[classes.blockRowLeftTitle, "font-40"].join(" ")}>
                                <Trans>main_5_1</Trans>
                            </h2>
                            <div className={[classes.blockRowLeftBottom, ""].join(" ")}>
                                <div className={[classes.blockRowLeftBottomLeft, ""].join(" ")}
                                    onClick={() => {
                                        swiper.slidePrev()
                                    }}
                                >
                                    <img src={img1} alt="" />
                                </div>
                                <div className={[classes.blockRowLeftBottomRight, ""].join(" ")}
                                    onClick={() => {
                                        swiper.slideNext()
                                    }}
                                >
                                    <img src={img1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className={[classes.blockRowRight, ""].join(" ")}>
                            <Swiper

                                slidesPerView={3}
                                breakpoints={{
                                    100: {
                                        slidesPerView: 1
                                    },
                                    481: {
                                        slidesPerView: 3
                                    }

                                }}
                                loop={true}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => {
                                    console.log(swiper)
                                    setSwiper(swiper)
                                }}
                            >
                                {items.map((i) => {
                                    return (
                                        <SwiperSlide key={i.id}>
                                            <div className={[classes.blockRowRightSlide, ""].join(" ")}>
                                                <div className={[classes.blockRowRightSlideItem, ""].join(" ")}>
                                                    <div className={[classes.blockRowRightSlideItemImg, ""].join(" ")}>
                                                        <img src={i.img} alt="" />
                                                    </div>
                                                    <p className={[classes.blockRowRightSlideItemTitle, "font-24"].join(" ")}>
                                                        <Trans>{i.name}</Trans>
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Slider