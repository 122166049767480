import HomePage from '../pages/HomePage/HomePage';
import ThankPage from '../pages/ThankPage/ThankPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/:lang/', element: HomePage },
    { path: '/thankyou', element: ThankPage },
    { path: '/:lang/thankyou', element: ThankPage },
    { path: '*', element: HomePage },
];

export const headerRoutes = [
];
