import React, { useState } from 'react'
import classes from "./If.module.scss"
import img1 from "../../../assets/img/main/5/left.png"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Trans, useTranslation } from 'react-i18next'
// Import Swiper styles
import 'swiper/css';
const If = () => {
    const { t } = useTranslation("");
    const [swiper, setSwiper] = useState({})
    const array = [
        { id: 1, title: "main_6_item11", text: "main_6_item12" },
        { id: 2, title: "main_6_item21", text: "main_6_item22" },
        { id: 3, title: "main_6_item31", text: "main_6_item32" },
        { id: 4, title: "main_6_item41", text: "main_6_item42" },
        { id: 5, title: "main_6_item51", text: "main_6_item52" },
        { id: 6, title: "main_6_item61", text: "main_6_item62" },
    ]

    return (
        <div className={[classes.block, "mb"].join(" ")}>
            <div className={[classes.blockBody, ""].join(" ")}>
                <div className="container">
                    <h2 className={[classes.blockTitle, "font-24"].join(" ")}>
                        <Trans>main_6_1</Trans>

                    </h2>
                    <div className={[classes.blockRow, ""].join(" ")}>
                        {array.map((i) => {
                            return (
                                <div key={i.id} className={[classes.blockRowColumn, ""].join(" ")}>
                                    <h3 className={[classes.blockRowColumnTitle, "font-20"].join(" ")}>
                                        <Trans>{i.title}</Trans>
                                    </h3>
                                    <p className={[classes.blockRowColumnText, "font-14"].join(" ")}>
                                        <Trans>{i.text}</Trans>
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                    <div className={[classes.mobSlider, ""].join(" ")}>
                        <div className={[classes.blockRowRight, ""].join(" ")}>
                            <Swiper

                                slidesPerView={1}
                                spaceBetween={60}
                                loop={true}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => {
                                    console.log(swiper)
                                    setSwiper(swiper)
                                }}
                            >
                                {array.map((i) => {
                                    return (
                                        <SwiperSlide key={i.id}>
                                            <div key={i.id} className={[classes.blockRowColumn, ""].join(" ")}>
                                                <h3 className={[classes.blockRowColumnTitle, "font-20"].join(" ")}>
                                                    <Trans>{i.title}</Trans>
                                                </h3>
                                                <p className={[classes.blockRowColumnText, "font-14"].join(" ")}>
                                                    <Trans>{i.text}</Trans>
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                        <div className={[classes.blockRowLeftBottom, ""].join(" ")}>
                            <div className={[classes.blockRowLeftBottomLeft, ""].join(" ")}
                                onClick={() => {
                                    swiper.slidePrev()
                                }}
                            >
                                <img src={img1} alt="" />
                            </div>
                            <div className={[classes.blockRowLeftBottomRight, ""].join(" ")}
                                onClick={() => {
                                    swiper.slideNext()
                                }}
                            >
                                <img src={img1} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default If