import React from 'react'
import classes from "./ThankPage.module.scss"
import Button from '../../components/GlobalComponents/Button/Button'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import Header from '../../components/GlobalComponents/Header/Header'
import Thank from '../../components/ThankyouComponents/Thank/Thank'
const ThankPage = () => {
    const { t } = useTranslation("");
    return (
        <div>
            <div className={[classes.hide, ""].join(" ")}>
                <Header />
            </div>
            <Thank />
        </div>

    )
}

export default ThankPage