import React from 'react'
import classes from "./Brokers.module.scss"
import img1 from "../../../assets/img/main/7/1.png"
import img2 from "../../../assets/img/main/7/2.png"
import img3 from "../../../assets/img/main/7/3.png"
import img4 from "../../../assets/img/main/7/4.png"
import img5 from "../../../assets/img/main/7/5.png"
import img6 from "../../../assets/img/main/7/6.png"
import img7 from "../../../assets/img/main/7/7.png"
import img8 from "../../../assets/img/main/7/8.png"
import { Trans, useTranslation } from 'react-i18next'
const Brokers = () => {
    const { t } = useTranslation("");
    return (
        <div className={[classes.brokers, "mb"].join(" ")}>
            <div className={[classes.brokersBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.brokersRow, ""].join(" ")}>
                        <div className={[classes.brokersRowLeft, ""].join(" ")}>
                            <h2 className={[classes.brokersTitle, "font-40"].join(" ")}>
                                <Trans>main_7_1</Trans>
                            </h2>
                            <p className={[classes.brokersRowLeftText, "font-14"].join(" ")}>
                                <Trans>main_7_2</Trans>
                            </p>
                        </div>
                        <div className={[classes.brokersRowRight, ""].join(" ")}>
                            <div className={[classes.brokersRowRightWrap, ""].join(" ")}>
                                <div className={[classes.brokersRowRightItem, ""].join(" ")}>
                                    <img src={img1} alt="" />
                                </div>
                                <div className={[classes.brokersRowRightItem, ""].join(" ")}>
                                    <img src={img2} alt="" />
                                </div>
                                <div className={[classes.brokersRowRightItem, ""].join(" ")}>
                                    <img src={img3} alt="" />
                                </div>
                                <div className={[classes.brokersRowRightItem, ""].join(" ")}>
                                    <img src={img4} alt="" />
                                </div>
                                <div className={[classes.brokersRowRightItem, ""].join(" ")}>
                                    <img src={img5} alt="" />
                                </div>
                                <div className={[classes.brokersRowRightItem, ""].join(" ")}>
                                    <img src={img6} alt="" />
                                </div>
                                <div className={[classes.brokersRowRightItem, ""].join(" ")}>
                                    <img src={img7} alt="" />
                                </div>
                                <div className={[classes.brokersRowRightItem, ""].join(" ")}>
                                    <img src={img8} alt="" />
                                </div>
                            </div>

                        </div>

                    </div>



                </div>
            </div>
        </div>
    )
}

export default Brokers