import React from 'react'
import classes from "./Why.module.scss"
import img1 from "../../../assets/img/main/3/1.png"
import img2 from "../../../assets/img/main/3/2.png"
import img3 from "../../../assets/img/main/3/3.png"
import img4 from "../../../assets/img/main/3/4.png"
import { Trans, useTranslation } from 'react-i18next'

const Why = () => {
    const { t } = useTranslation("");
    return (
        <div className={[classes.why, "mb"].join(" ")}>
            <div className={[classes.whyBody, ""].join(" ")}>
                <div className="container">
                    <h2 className={[classes.whyTitle, "font-40"].join(" ")}>
                        <Trans>main_3_1</Trans>
                    </h2>
                    <div className={[classes.whyRow, ""].join(" ")}>
                        <div className={[classes.whyRowColumn, ""].join(" ")}>
                            <div className={[classes.whyRowColumnImg, ""].join(" ")}>
                                <img src={img1} alt="Почему стоит выбрать нас?" />
                            </div>
                            <h3 className={[classes.whyRowColumnTitle, "font-24"].join(" ")}>
                                <Trans>main_3_item11</Trans>
                            </h3>
                            <p className={[classes.whyRowColumnText, "font-14"].join(" ")}>
                                <Trans>main_3_item12</Trans>
                            </p>
                        </div>
                        <div className={[classes.whyRowColumn, ""].join(" ")}>
                            <div className={[classes.whyRowColumnImg, ""].join(" ")}>
                                <img src={img2} alt="Почему стоит выбрать нас?" />
                            </div>
                            <h3 className={[classes.whyRowColumnTitle, "font-24"].join(" ")}>
                                <Trans>main_3_item21</Trans>
                            </h3>
                            <p className={[classes.whyRowColumnText, "font-14"].join(" ")}>
                                <Trans>main_3_item22</Trans>
                            </p>
                        </div>
                        <div className={[classes.whyRowColumn, ""].join(" ")}>
                            <div className={[classes.whyRowColumnImg, ""].join(" ")}>
                                <img src={img3} alt="Почему стоит выбрать нас?" />
                            </div>
                            <h3 className={[classes.whyRowColumnTitle, "font-24"].join(" ")}>
                                <Trans>main_3_item31</Trans>
                            </h3>
                            <p className={[classes.whyRowColumnText, "font-14"].join(" ")}>
                                <Trans>main_3_item32</Trans>
                            </p>
                        </div>
                        <div className={[classes.whyRowColumn, ""].join(" ")}>
                            <div className={[classes.whyRowColumnImg, ""].join(" ")}>
                                <img src={img4} alt="Почему стоит выбрать нас?" />
                            </div>
                            <h3 className={[classes.whyRowColumnTitle, "font-24"].join(" ")}>
                                <Trans>main_3_item41</Trans>
                            </h3>
                            <p className={[classes.whyRowColumnText, "font-14"].join(" ")}>
                                <Trans>main_3_item42</Trans>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Why