import React, { useState } from 'react'
import classes from "./Bottom.module.scss"
import img1 from "../../../assets/img/main/8/1.png"
import Button from '../../GlobalComponents/Button/Button'
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import { Trans, useTranslation } from 'react-i18next'
const Bottom = () => {
    const { t } = useTranslation("");
    const [check, setCheck] = useState(false)
    const [checkValid, setCheckValid] = useState(true)
    const [name, setName] = useState("")
    const [nameValid, setNameValid] = useState(true)
    const [phone, setPhone] = useState("")
    const [phoneValid, setPhoneValid] = useState(true)
    const navigate = useNavigate();

    async function send() {
        const messageData = {
            siteName: window.location.hostname,
            name,
            phone,
        };
        try {
            const result = await axios.post('https://civbt.xyz/api/bot/SDPCDBN9OP', messageData);
            if (result.status === 200) {
                navigate("/thankyou")
                return true;
            } else {
                alert('Something went wrong. Try again');
                return false;
            }
        } catch (error) {
            if (error.response.data.status === 403) {
                alert(error.response.data.message);

                return;
            }
            alert(error.message);
        }

    }


    function valid() {
        let letsGo = true
        if (!check) {
            setCheckValid(false)
            letsGo = false
        } else {
            setCheckValid(true)
        }
        if (name.length < 2) {
            setNameValid(false)
            letsGo = false
        } else {
            setNameValid(true)
        }
        if (phone.length < 2) {
            setPhoneValid(false)
            letsGo = false
        } else {
            setPhoneValid(true)
        }
        return letsGo
    }

    return (
        <div className={[classes.block, "mt mb"].join(" ")} id='mainForm'>
            <div className={[classes.blockBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.blockRow, ""].join(" ")}>
                        <div className={[classes.blockRowLeft, ""].join(" ")}>
                            <p className={[classes.blockRowLeftText, "font-20"].join(" ")}>
                                <Trans>main_8_left_1</Trans>
                            </p>
                            <div className={[classes.blockRowLeftForm, ""].join(" ")}>
                                <div className={nameValid ? [classes.blockRowLeftFormInput, ""].join(" ") : [classes.blockRowLeftFormInput, classes.error, ""].join(" ")}>
                                    <input type="text" placeholder={t("main_8_left_2")} className='font-16' value={name} onChange={(e) => {
                                        setName(e.target.value)
                                        valid()
                                    }} />
                                </div>
                                <div className={phoneValid ? [classes.blockRowLeftFormInput, ""].join(" ") : [classes.blockRowLeftFormInput, classes.error, ""].join(" ")}>
                                    <input type="text" placeholder={t("main_8_left_3")} className='font-16' value={phone} onChange={(e) => {
                                        setPhone(e.target.value)
                                        valid()
                                    }} />
                                </div>
                                <div
                                    onClick={() => {
                                        setCheck(!check)
                                        valid()
                                    }}
                                    className={check ? [classes.blockRowLeftFormCheckout, classes.active, "font-14"].join(" ") : checkValid ? [classes.blockRowLeftFormCheckout, "font-14"].join(" ") : [classes.blockRowLeftFormCheckout, classes.error, "font-14"].join(" ")}>
                                    <span></span>
                                    <Trans>main_8_left_4</Trans>
                                </div>
                                <div className={[classes.blockRowLeftFormBtn, ""].join(" ")}
                                    onClick={() => {
                                        let res = valid()
                                        if (res) {
                                            send()
                                        } else {
                                            console.log("error")
                                        }
                                    }}
                                >
                                    <Button login={"true"}><Trans>btn_submit</Trans></Button>
                                </div>

                            </div>
                        </div>
                        <div className={[classes.blockRowRight, ""].join(" ")}>
                            <div className={[classes.blockRowRightContent, ""].join(" ")}>
                                <h3 className={[classes.blockRowRightContentTitle, "font-40"].join(" ")}>
                                    <Trans>main_8_right_1</Trans>
                                </h3>
                                <p className={[classes.blockRowRightContentSubTitle, "font-14"].join(" ")}>
                                    <Trans>main_8_right_2</Trans>
                                </p>
                                <p className={[classes.blockRowRightContentLocation, classes.mail, "font-14"].join(" ")}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.6626 5C19.9321 5 20.1526 5.22051 20.1526 5.49005V5.55207C20.1526 5.82161 19.9703 6.16616 19.7475 6.31776L11.9772 12.0929C11.7544 12.2445 11.3896 12.2445 11.1667 12.093L3.40529 6.32642C3.18242 6.17482 3 5.83037 3 5.56083V5.49015C3 5.22061 3.22051 5.0001 3.49005 5.0001H19.6626V5Z" fill="#BFAA8A"/>
                                        <path d="M11.1766 13.7163C11.3941 13.8756 11.7499 13.8757 11.9675 13.7165L19.7572 8.01613C19.9748 7.85695 20.1526 7.94722 20.1526 8.21676V18.5097C20.1526 18.7792 19.9321 18.9997 19.6626 18.9997H3.49005C3.22051 18.9997 3 18.7792 3 18.5097V8.21676C3 7.94722 3.17789 7.85705 3.39535 8.01633L11.1766 13.7163Z" fill="#BFAA8A"/>
                                    </svg>
                                    <a href="mailto:support@legalbalance.online" className="font-14">
                                        support@legalbalance.online
                                    </a>
                                </p>
                                <p className={[classes.blockRowRightContentLocation, "font-14"].join(" ")}>
                                    <img src={img1} alt="" />
                                    <Trans>main_8_right_3</Trans>
                                </p>
                                <div className={[classes.blockRowRightContentBottom, ""].join(" ")}>
                                    <div className={[classes.blockRowRightContentBottomItem, ""].join(" ")}>
                                        <p className='font-12'><Trans>main_8_right_4</Trans></p>
                                        <p className='font-12'><Trans>main_8_right_5</Trans></p>
                                    </div>
                                    <div className={[classes.blockRowRightContentBottomItem, ""].join(" ")}>
                                        <p className='font-12'><Trans>PVM</Trans></p>
                                        <p className='font-12'><Trans>LT100005742818</Trans></p>
                                    </div>
                                    <div className={[classes.blockRowRightContentBottomItem, ""].join(" ")}>
                                        <p className='font-12'><Trans>main_8_right_6</Trans></p>
                                        <p className='font-12'><Trans>main_8_right_7</Trans></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bottom