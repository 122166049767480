import logo from '../assets/img/logo.png';
import img1 from '../assets/img/main/1/1.png';
import img2 from '../assets/img/main/1/mob.png';


export const images = [
    logo,
    img1, img2

];

